import React from "react";
import "./lokasi.scss";
import mapsimage from "../../media/Screenshot 2024-07-30 104051.png";
import mobilemaps from "../../media/WhatsApp Image 2024-07-30 at 10.49.31.jpeg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faGraduationCap,
  faHouse,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";

const lokasi = () => {
  return (
    <div id="location" className="lokasi">
      <div className="judul">Lokasi</div>
      <div className="maps-lokasi">
        <img
          id="location"
          className="img-lokasi-dekstop"
          src={mapsimage}
          alt="maps"
        />
        <img
          id="lokasi"
          className="img-lokasi-mobile"
          src={mobilemaps}
          alt="maps"
        />
      </div>
      <div className="benefit-container">
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faGraduationCap} /> &nbsp;Education
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;SMA Islam Al Azhar 18 Grand Wisata
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Sekolah Kristen IPEKA Grand Wisata
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;State Senior High School 9 Bekasi
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;SMK Negeri 3 Kota Bekasi
            </div>
          </div>
        </div>
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faHouse} /> &nbsp;Health Care
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Rumah Sakit Eka Hospital
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Rumah Sakit Hermina
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Rumah Sakit Permata
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Rumah Sakit Mitra Keluarga
            </div>
          </div>
        </div>
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faBuilding} /> &nbsp;Entertainment
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Pasar Modern
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Wisata Kuliner
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Living World Mall
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Go! Wet Waterpark
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default lokasi;
