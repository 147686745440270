import React from "react";
import "./newlaunching1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import Kaia8images from "./Kaia8.js";
import Kaia9images from "./Kaia9.js";
import Kaia10images from "./Kaia10.js";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Kaia8 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(The%20Kaia%208)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Kaia9 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(The%20Kaia%2010)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Kaia10 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(The%20Kaia%2012)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="containercard88">
        <div className="card">
          <Slider {...settings}>
            {Kaia8images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="nama-rumah">Kaia 8</div>
            <div className="gridmap">
              <div className="namalokasi">
                Tanpa DP, Cicilan Start 15 Juta/Bulan
              </div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="gridspek">
                <FontAwesomeIcon
                  color="#EEAE5B"
                  icon={faArrowsUpDownLeftRight}
                />
                <span> 144m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faHouse} />
                <span> 195m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faBed} />
                <span> 4</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faShower} />
                <span> 4</span>
              </div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Kaia8} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Kaia9images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="nama-rumah">Kaia 10</div>
            <div className="gridmap">
              <div className="namalokasi">
                Tanpa DP, Cicilan Start 22 Juta/Bulan
              </div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="gridspek">
                <FontAwesomeIcon
                  color="#EEAE5B"
                  icon={faArrowsUpDownLeftRight}
                />
                <span> 220m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faHouse} />
                <span> 260m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faBed} />
                <span> 4</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faShower} />
                <span> 5</span>
              </div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Kaia9} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Kaia10images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="nama-rumah">Kaia 12</div>
            <div className="gridmap">
              <div className="namalokasi">
                Tanpa DP, Cicilan Start 30 Juta/Bulan
              </div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="gridspek">
                <FontAwesomeIcon
                  color="#EEAE5B"
                  icon={faArrowsUpDownLeftRight}
                />
                <span> 240m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faHouse} />
                <span> 328m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faBed} />
                <span> 4</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faShower} />
                <span> 5</span>
              </div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Kaia10} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
