import React from "react";
import "./Produk2.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Hannamimages from "./Lemonade.js";
import Porticoimages from "./WT.js";
import Theoakwoodimages from "./Garden.js";
import Tudorimages from "./Cherry.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLocationDot,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import Lemonadeimages from "./Lemonade.js";
import Gardenimages from "./Garden.js";
import Cherryimages from "./Cherry.js";
import WTimages from "./WT.js";

const Produk2 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Lemonadewa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Lemonade)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Gardenwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Garden%20Loft)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Cherrywa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Cherry%20Ville)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const WTwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Water%20Terrace)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="container-card3">
        <div className="card">
          <Slider {...settings}>
            {Lemonadeimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Grand Lemonade</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="grey" icon={faMedal} />
              <div className="namalokasi">Tanpa DP</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">7</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 90m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 66m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 4</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Lemonadewa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Gardenimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Garden Loft</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="grey" icon={faMedal} />
              <div className="namalokasi">Tanpa DP</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">9</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 110m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 101m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 4+1</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 3+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Gardenwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Cherryimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Cherry Ville</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="grey" icon={faMedal} />
              <div className="namalokasi">Tanpa DP</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">10</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 128m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 108m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 5</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 5</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Cherrywa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {WTimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Water Terrace</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="grey" icon={faMedal} />
              <div className="namalokasi">Siap Huni</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">13</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 200m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 189m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 4+1</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 3+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={WTwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk2;
