import React from "react";
import "./newlaunching.scss";
import Newlaunching1 from "./newlaunching1/newlaunching1.jsx";

const rumah = () => {
  return (
    <div id="thekaia" className="rumah1">
      <div>
        <div className="Judulnewlaunch">New Launching</div>
        <h1>Cluster The Kaia at Yara</h1>
        <h2>The Heart of Grand Wisata</h2>
        <div className="rumah-card">
          <Newlaunching1 />
        </div>
      </div>
    </div>
  );
};

export default rumah;
