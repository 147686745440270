import React from "react";
import "./about.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import logosumban from "../../media/log.jpg";

const aboutsumban = () => {
  const aboutwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Grand%20Wisata%20https://www.sinarmas-grandwisata.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div id="about" className="about-sumban">
      <div className="containerlogo">
        <div className="logosumban">
          <img className="gambarsumban" src={logosumban} alt="logo sumban" />
        </div>
      </div>
      <div className="containerdesk">
        <div className="judul1">
          <h1>New Age of Luxury Living</h1>
        </div>
        <div className="desk">
          Grand Wisata adalah salah satu proyek pengembangan properti yang
          dikelola oleh Sinarmas Land.Sinarmas Land menginisiasi untuk membangun
          salah satu kota mandiri yang terletak di timur Jakarta yang lengkap
          dengan berbagai fasilitas kawasan dan cluster dengan menggabungkan
          konsep asri dan komersial yang serba lengkap dan mengikuti life style
          masa kini.Sinarmas Land juga berkomitmen untuk menyediakan fasilitas
          dan layanan berkualitas bagi penghuni dan pengunjung proyek mereka.
          Fasilitas yang mungkin ditawarkan dapat mencakup area rekreasi, taman,
          kolam renang, pusat kebugaran, pusat perbelanjaan, pusat kesehatan,
          dan fasilitas pendukung lainnya. Kehadiran Kota Mandiri Grandwisata by
          Sinarmasland tidak hanya menyediakan fasilitas yang lengkap, dengan
          masterplan yang matang dan terencana serta lokasi yang sangat
          strategis Grandwisata mempunyai koneksi langsung ke akses tol Japek 1
          & 2, JORR 2 (cimaci), dan Becakayu Serta dekat dengan Stasiun LRT, KRL
          dan KCIC.
        </div>
      </div>
    </div>
  );
};

export default aboutsumban;
