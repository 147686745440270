import React from "react";
import "./Vicente.scss";
import Vicente from "./newlaunching1/Vicente1.jsx";

const rumah = () => {
  return (
    <div id="vicente" className="rumah1">
      <div>
        <div className="Judulnewlaunch">New Launching</div>
        <h1>Vicente at Klasika</h1>
        <div className="rumah-card">
          <Vicente />
        </div>
      </div>
    </div>
  );
};

export default rumah;
