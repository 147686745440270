import React from "react";
import "./Vicente1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import Vicente6images from "./Vicente6.js";
import Vicente7images from "./Vicente7.js";
import Vicente8images from "./Vicente8.js";
import Vicente10images from "./Vicente10.js";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Vicente6 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Vicente6)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Vicente7 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Vicente7)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Vicente8 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Vicente8)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Vicente10 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Vicente10)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="containercard88">
        <div className="card">
          <Slider {...settings}>
            {Vicente6images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="nama-rumah">Vicente 6</div>
            <div className="gridmap">
              <div className="namalokasi">
                Tanpa DP, Cicilan Start 6 Juta/Bulan
              </div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="gridspek">
                <FontAwesomeIcon
                  color="#EEAE5B"
                  icon={faArrowsUpDownLeftRight}
                />
                <span> 72m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faHouse} />
                <span> 87m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faBed} />
                <span> 3</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faShower} />
                <span> 2</span>
              </div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Vicente6} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Vicente7images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="nama-rumah">Vicente 7</div>
            <div className="gridmap">
              <div className="namalokasi">
                Tanpa DP, Cicilan Start 8 Juta/Bulan
              </div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="gridspek">
                <FontAwesomeIcon
                  color="#EEAE5B"
                  icon={faArrowsUpDownLeftRight}
                />
                <span> 126m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faHouse} />
                <span> 106m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faBed} />
                <span> 3+1</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faShower} />
                <span> 3+1</span>
              </div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Vicente7} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Vicente8images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="nama-rumah">Vicente 8</div>
            <div className="gridmap">
              <div className="namalokasi">
                Tanpa DP, Cicilan Start 10 Juta/Bulan
              </div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="gridspek">
                <FontAwesomeIcon
                  color="#EEAE5B"
                  icon={faArrowsUpDownLeftRight}
                />
                <span> 120m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faHouse} />
                <span> 128m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faBed} />
                <span> 4+1</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faShower} />
                <span> 3+1</span>
              </div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Vicente8} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Vicente10images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="nama-rumah">Vicente 10</div>
            <div className="gridmap">
              <div className="namalokasi">
                Tanpa DP, Cicilan Start 12 Juta/Bulan
              </div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="gridspek">
                <FontAwesomeIcon
                  color="#EEAE5B"
                  icon={faArrowsUpDownLeftRight}
                />
                <span> 140m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faHouse} />
                <span> 154m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faBed} />
                <span> 4+1</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faShower} />
                <span> 4+1</span>
              </div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Vicente10} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
