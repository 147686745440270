import React from "react";
import "./Produk1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Tanamasimages from "./Tanamas.js";
import Levanteimages from "./Levante.js";
import O8images from "./O8.js";
import Zlivingimages from "./Zliving.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLocationDot,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const O8wa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(O8)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Tanamaswa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Tanamas)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Levantewa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Levante)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Zlivwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Z-living)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="containercard2">
        <div className="card">
          <Slider {...settings}>
            {O8images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">O8</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="white" icon={faMedal} />
              <div className="namalokasi">Tanpa DP</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">6</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 66m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 65m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={O8wa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Tanamasimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Tanamas</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="white" icon={faMedal} />
              <div className="namalokasi">Tanpa DP</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">6</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 60m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 73m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 4</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Tanamaswa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Zlivingimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Z Living</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="white" icon={faMedal} />
              <div className="namalokasi">Tanpa DP</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">6</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 66m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 88m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 4</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 4</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Zlivwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Levanteimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Levante</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="white" icon={faMedal} />
              <div className="namalokasi">Tanpa DP</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">6</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 140m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 135m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 5</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 4</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Levantewa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
